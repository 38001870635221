import { create } from "zustand";

const useCurrentUserStore = create((set) => ({
  loading: false,
  dataChanged: true,
  currentUser: "",
  currentUserInfo: "",
  currentUserAddress: "",

  setLoading: (loading) => set({ loading }),

  setDataChanged: (dataChanged) => set({ dataChanged }),

  setCurrentUser: (newCurrentUser) => set({ currentUser: newCurrentUser }),

  setCurrentUserInfo: (newCurrentUserInfo) =>
    set({ currentUserInfo: newCurrentUserInfo }),

  setCurrentUserAddress: (newCurrentUserAddress) =>
    set({ currentUserAddress: newCurrentUserAddress }),

  resetCurrentUserStore: () =>
    set(() => ({
      loading: false,
      dataChanged: true,
      currentUser: "",
      currentUserInfo: "",
      currentUserAddress: "",
    })),
}));

export default useCurrentUserStore;
