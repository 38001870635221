import { useEffect } from "react";
import eventEmitter from "../shared/EventEmitter";
import useAppLogic from "../hooks/AppLogic";

const LogoutListener = () => {
  const { clearAppCache } = useAppLogic();

  useEffect(() => {
    const handleLogout = () => {
      console.log("Logout event received");
      clearAppCache(true);
    };

    eventEmitter.on("logout", handleLogout);

    return () => {
      eventEmitter.off("logout", handleLogout);
    };
  }, [clearAppCache]);

  return null;
};

export default LogoutListener;
