import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ElementTransition = ({ children }) => {
  const [isMounted, setIsMounted] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsMounted(true);
  }, [location]);

  return (
    <div
      className={`transition-transform duration-150 ease-in-out transform ${
        isMounted ? "translate-y-0" : "-translate-y-2"
      } flex items-center justify-center w-full h-full`}
    >
      {children}
    </div>
  );
};

export default ElementTransition;
