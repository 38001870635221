import React, { useState } from "react";
import AssetsItem from "./AssetsItem";
import AssetsTypeSummary from "./AssetsTypeSummary";
import useAssetsStore from "../../../store/AssetsStore";

const AssetsList = ({ openModal }) => {
  const [expandedCategories, setExpandedCategories] = useState({});

  const { assets, assetTypes, loading } = useAssetsStore();

  // Group assets by assetType
  const groupedAssets = assets.reduce((grouped, asset) => {
    const assetType = assetTypes.find((type) => type.id === asset.assetTypeId);
    if (!grouped[assetType.name]) {
      grouped[assetType.name] = [];
    }
    grouped[assetType.name].push(asset);
    return grouped;
  }, {});

  const sortedCategories = Object.keys(groupedAssets).sort((a, b) =>
    a.localeCompare(b)
  );

  const toggleCategory = (categoryName) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [categoryName]: !prevState[categoryName],
    }));
  };

  return (
    <div className="mt-8 px-4">
      {loading ? (
        <div className="text-center text-xl mt-8 text-black dark:text-gray-300">
          Loading...
        </div>
      ) : (
        <>
          {sortedCategories.map((assetTypeName) => {
            const isExpanded = expandedCategories[assetTypeName] || false;
            const assetsInCategory = groupedAssets[assetTypeName];

            const totalPurchasePrice = assetsInCategory.reduce(
              (total, asset) => total + asset.purchasePrice,
              0
            );
            const totalCurrentWorth = assetsInCategory.reduce(
              (total, asset) => total + asset.currentWorth,
              0
            );

            return (
              <div key={assetTypeName} className="mb-8">
                {/* Collapsible Header */}
                <div
                  className="cursor-pointer"
                  onClick={() => toggleCategory(assetTypeName)}
                >
                  {isExpanded ? (
                    <h2 className="text-2xl font-semibold text-black dark:text-gray-300 mb-4">
                      {assetTypeName}
                      <span className="text-sm font-light">
                        {" (collapse)"}
                      </span>
                    </h2>
                  ) : (
                    <AssetsTypeSummary
                      assetTypeName={assetTypeName}
                      totalPurchasePrice={totalPurchasePrice}
                      totalCurrentWorth={totalCurrentWorth}
                    />
                  )}
                </div>

                {/* Expanded Assets */}
                {isExpanded && (
                  <>
                    {assetsInCategory.map((asset) => (
                      <AssetsItem
                        key={asset.id}
                        asset={asset}
                        assetType={assetTypeName}
                        onEdit={openModal}
                      />
                    ))}

                    <hr className="opacity-50 mt-8" />
                  </>
                )}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AssetsList;
