import React from "react";
import Menu from "./Menu/Menu";
import DarkModeToggle from "../../components/DarkModeToggle/DarkModeToggle";
import PageSelector from "./PageSelector/PageSelector";

const Header = ({ menuHidden }) => {
  return (
    <header className="flex justify-between items-center w-full p-4 bg-blue-600 text-white shadow-lg fixed top-0 left-0 right-0 z-50">
      <PageSelector menuHidden={menuHidden} />

      {!menuHidden && (
        <div className="flex">
          <DarkModeToggle />

          <Menu />
        </div>
      )}
    </header>
  );
};

export default Header;
