import React, { useEffect, useState } from "react";
import useTransactionsLogic from "../../../hooks/TransactionsLogic";
import useTransactionsStore from "../../../store/TransactionsStore";
import Label from "../../Modal/Label";

const TransactionsSettingsTags = ({ currentUser }) => {
  const { addTransactionTag, editTransactionTag, removeTransactionTag } =
    useTransactionsLogic();

  const { transactionTags, loading } = useTransactionsStore();

  const [tags, setTags] = useState([]);

  const [newTag, setNewTag] = useState({
    id: null,
    name: "",
    description: "",
  });
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    setTags(
      transactionTags.filter((category) => category.userId === currentUser.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionTags]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewTag((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (editingId === null) {
      handleAddTransactionTag();
    } else {
      handleEditTransactionTag();
    }

    resetForm();
  };

  const handleAddTransactionTag = async () => {
    const result = await addTransactionTag(newTag);
    if (result) {
      alert("An error occured. Please try again.");
    }
  };

  const handleEditTransactionTag = async () => {
    const result = await editTransactionTag(newTag);
    if (result) {
      alert("An error occured. Please try again.");
    }
  };

  const handleEdit = (category) => {
    setNewTag(category);
    setEditingId(category.id);
  };

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this transaction category?"
    );
    if (isConfirmed) {
      const category = { userId: currentUser.id, categoryId: id };
      const result = await removeTransactionTag(category);

      if (result) {
        alert(
          "Tag couldn't be deleted. Please try again.\n\nTip: Only unused tags can be deleted."
        );
      }
    }
  };

  const cancelEdit = () => {
    resetForm();
  };

  const resetForm = () => {
    setNewTag({ id: null, name: "", description: "" });
    setEditingId(null);
  };

  return (
    <div className="space-y-4">
      <label className="block text-gray-700 dark:text-gray-300 font-semibold text-center">
        All Tags
      </label>

      <div className="border p-4 mt-4 min-h-64 max-h-64 overflow-y-auto space-y-4 bg-gray-50 dark:bg-gray-300 rounded">
        {loading ? (
          <div className="text-center text-base mt-4 text-black dark:text-gray-300">
            Loading...
          </div>
        ) : (
          <>
            {tags.length > 0 ? (
              tags.map((category) => (
                <div
                  key={category.id}
                  className="flex justify-between items-center border-b pb-2"
                >
                  <div>
                    <h3 className="font-bold text-gray-800">{category.name}</h3>
                    <p className="text-gray-600">{category.description}</p>
                  </div>

                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEdit(category)}
                      className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(category.id)}
                      className="bg-red-400 text-white py-1 px-3 rounded hover:bg-red-500"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-base mt-4 text-black dark:text-gray-300">
                No transaction tags
              </div>
            )}
          </>
        )}
      </div>

      <label className="block text-gray-700 dark:text-gray-300 font-semibold text-center">
        {editingId === null ? "Add New Tag" : "Update Tag"}
      </label>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="mb-4">
          <Label text={"Name"} isRequired={true} />
          <input
            type="text"
            id="name"
            name="name"
            value={newTag.name}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mt-1"
            maxLength={50}
            required
          />
          <div className="text-right text-sm text-gray-500">
            {newTag.name.length}/{50} characters
          </div>
        </div>

        <div className="mb-4">
          <Label text={"Description"} />
          <textarea
            type="text"
            id="description"
            name="description"
            value={newTag.description}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mt-1"
            maxLength={255}
          />
          <div className="text-right text-sm text-gray-500">
            {newTag.description.length}/{255} characters
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          {editingId !== null && (
            <button
              type="button"
              onClick={cancelEdit}
              className="bg-gray-300 py-2 px-4 mb-2 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
          )}

          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 mb-2 rounded hover:bg-blue-600 disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed"
            disabled={newTag.name.length === 0}
          >
            {editingId === null ? "Add Tag" : "Update Tag"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TransactionsSettingsTags;
