import React from "react";

const Label = ({ text, isRequired }) => {
  return (
    <div className="flex">
      {isRequired && <label className="text-red-500">*</label>}

      <label className="block text-gray-700 dark:text-gray-400 mb-0.5">
        {text}
      </label>
    </div>
  );
};

export default Label;
