import React from "react";
import PageTransition from "../../shared/PageTransition";

const Body = ({ children }) => {
  return (
    <PageTransition>
      <div className="pt-16 bg-white dark:bg-gray-900 min-h-screen">
        {children}
      </div>
    </PageTransition>
  );
};

export default Body;
