import React, { useEffect, useState } from "react";
import useTransactionsStore from "../../../store/TransactionsStore";

const TransactionsFiltersAccount = ({
  displayTransactions,
  timeFrameTransactions,
  setFilteredTransactionsAccount,
}) => {
  const {
    loading,
    transactions,
    transactionAccounts,
    selectedAccount,
    setSelectedAccount,
    selectedTimeFrame,
    selectedCategory,
  } = useTransactionsStore();

  const getUsedAccountIds = () => {
    const selection =
      selectedCategory === -1 ? timeFrameTransactions : displayTransactions;

    return new Set(
      selection.map((transaction) => transaction.transactionAccountId)
    );
  };

  const [usedAccountIds, setUsedAccountIds] = useState(getUsedAccountIds());

  const usedAccounts = transactionAccounts
    .filter((account) => usedAccountIds.has(account.id))
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    setUsedAccountIds(getUsedAccountIds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayTransactions, selectedCategory]);

  useEffect(() => {
    filterTransactionsByAccount(selectedAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, timeFrameTransactions, transactions]);

  const handleAccountChange = (event) => {
    const accountId = parseInt(event.target.value, 10);
    setSelectedAccount(accountId);
  };

  const filterTransactionsByAccount = (id) => {
    if (id === -1) {
      setFilteredTransactionsAccount(
        selectedTimeFrame === 3 ? transactions : timeFrameTransactions
      );
    } else {
      setFilteredTransactionsAccount(
        (selectedTimeFrame === 3 ? transactions : timeFrameTransactions).filter(
          (transaction) => transaction.transactionAccountId === id
        )
      );
    }
  };

  return (
    <>
      {transactionAccounts.length > 0 && (
        <div className="text-center mt-2 space-x-4">
          <select
            className="px-3 py-2 bg-gray-100 dark:bg-gray-400 rounded-full transition-transform transform hover:scale-105 cursor-pointer disabled:cursor-not-allowed"
            onChange={handleAccountChange}
            value={selectedAccount}
            disabled={loading}
          >
            <option value="-1">All accounts</option>

            {usedAccounts.map((account) => (
              <option key={account.id} value={account.id}>
                {account.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};

export default TransactionsFiltersAccount;
