import { create } from "zustand";

const useTransactionsStore = create((set) => ({
  loading: false,
  dataChanged: true,
  transactions: [],
  transactionCategories: [],
  selectedCategory: -1,
  transactionAccounts: [],
  selectedAccount: -1,
  transactionTags: [],
  selectedTag: -1,
  selectedTimeFrame: 1,

  setLoading: (loading) => set({ loading }),

  setDataChanged: (dataChanged) => set({ dataChanged }),

  setTransactions: (newTransactions) => set({ transactions: newTransactions }),

  setTransactionCategories: (newTransactionCategories) =>
    set({ transactionCategories: newTransactionCategories }),

  setSelectedCategory: (selectedCategory) => set({ selectedCategory }),

  setTransactionAccounts: (newTransactionAccounts) =>
    set({ transactionAccounts: newTransactionAccounts }),

  setSelectedAccount: (selectedAccount) => set({ selectedAccount }),

  setTransactionTags: (newTransactionTags) =>
    set({ transactionTags: newTransactionTags }),

  setSelectedTag: (selectedTag) => set({ selectedTag }),

  setSelectedTimeFrame: (selectedTimeFrame) => set({ selectedTimeFrame }),

  resetTransactionsStore: () =>
    set(() => ({
      loading: false,
      dataChanged: true,
      transactions: [],
      transactionCategories: [],
      selectedCategory: -1,
      transactionAccounts: [],
      selectedAccount: -1,
      transactionTags: [],
      selectedTag: -1,
      selectedTimeFrame: 1,
    })),
}));

export default useTransactionsStore;
