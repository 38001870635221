import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "../../services/ApiService/ApiCall";

const LogIn = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!usernameOrEmail || !password) {
      alert("Please enter both username/email and password");
      return;
    }

    setIsLoading(true);

    try {
      const requestUrl = `/Auth/AuthUser`;
      const result = await makeApiCall("POST", requestUrl, {
        UsernameOrEmail: usernameOrEmail,
        Password: password,
      });

      if (result?.status === 200) {
        console.log("**Login Successful**");
      } else {
        alert("Login unsuccessful, please try again.");
      }
    } catch (error) {
      console.error("Failed to authenticate user:", error.response?.status);
      if (error.response?.status === 404) {
        alert("Failed to login: user doesn't exist.");
      } else if (error.response?.status === 401) {
        alert("Username and password combination is incorrect.");
      } else {
        alert("Failed to login, please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50">
      <div>
        <form
          onSubmit={handleLogin}
          className="flex flex-col items-center w-full max-w-3xl p-12 bg-white rounded-lg shadow-xl"
        >
          <div className="select-none flex">
            <img
              src="/images/logo512.png"
              alt="Wherepare Logo"
              className="h-10 w-10 mr-2"
            />
            <h2 className="text-3xl font-bold text-gray-800 mb-12">
              Wherepare
            </h2>
          </div>

          <div className="w-full mb-6">
            <label
              htmlFor="username"
              className="block text-gray-700 text-base font-medium mb-3"
            >
              Username or Email:
            </label>
            <input
              type="text"
              autoCapitalize="none"
              id="username"
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              disabled={isLoading}
              required
              className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
              maxLength={255}
            />
          </div>

          <div className="w-full mb-6">
            <label
              htmlFor="password"
              className="block text-gray-700 text-base font-medium mb-3"
            >
              Password:
            </label>
            <input
              type="password"
              autoCapitalize="none"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              required
              className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
              maxLength={128}
            />
          </div>

          <button
            type="submit"
            className={`py-3 px-5 rounded ${
              usernameOrEmail.length === 0 || password.length === 0
                ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
          >
            {isLoading ? "Logging in..." : "Log In"}
          </button>
        </form>

        <div className="flex flex-col items-center mt-2 text-center">
          <span
            className="text-center mb-3 font-base text-gray-400 cursor-pointer"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Your Password?
          </span>

          <div className="text-gray-600 mb-2">Not a member yet?</div>
          <button
            onClick={() => navigate("/signup")}
            className="bg-green-500 text-white px-4 py-2 rounded text-base font-medium hover:bg-green-600"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
