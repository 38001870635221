import React, { useEffect, useState } from "react";
import useTransactionsStore from "../../../store/TransactionsStore";

const TransactionsFiltersCategory = ({
  displayTransactions,
  timeFrameTransactions,
  setFilteredTransactionsCategory,
}) => {
  const {
    loading,
    transactions,
    transactionCategories,
    selectedCategory,
    setSelectedCategory,
    selectedTimeFrame,
    selectedAccount,
  } = useTransactionsStore();

  const getUsedCategoryIds = () => {
    const selection =
      selectedAccount === -1 ? timeFrameTransactions : displayTransactions;

    return new Set(
      selection.map((transaction) => transaction.transactionCategoryId)
    );
  };

  const [usedCategoryIds, setUsedCategoryIds] = useState(getUsedCategoryIds());

  const usedCategories = transactionCategories
    .filter((category) => usedCategoryIds.has(category.id))
    .sort((a, b) => a.name.localeCompare(b.name));

  const expenseCategories = usedCategories.filter(
    (category) => category.isIncome === false
  );

  const incomeCategories = usedCategories.filter(
    (category) => category.isIncome === true
  );

  useEffect(() => {
    setUsedCategoryIds(getUsedCategoryIds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayTransactions, selectedAccount]);

  useEffect(() => {
    filterTransactionsByCategory(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, timeFrameTransactions, transactions]);

  const handleCategoryChange = (event) => {
    const categoryId = parseInt(event.target.value, 10);
    setSelectedCategory(categoryId);
  };

  const filterTransactionsByCategory = (id) => {
    if (id === -1) {
      setFilteredTransactionsCategory(
        selectedTimeFrame === 3 ? transactions : timeFrameTransactions
      );
    } else {
      setFilteredTransactionsCategory(
        (selectedTimeFrame === 3 ? transactions : timeFrameTransactions).filter(
          (transaction) => transaction.transactionCategoryId === id
        )
      );
    }
  };

  return (
    <>
      {transactionCategories.length > 0 && (
        <div className="text-center mt-2 space-x-4">
          <select
            className="px-3 py-2 bg-gray-100 dark:bg-gray-400 rounded-full transition-transform transform hover:scale-105 cursor-pointer disabled:cursor-not-allowed"
            onChange={handleCategoryChange}
            value={selectedCategory}
            disabled={loading}
          >
            <option value="-1">All categories</option>

            <optgroup label="Expense" className="bg-gray-200 dark:bg-gray-500">
              {expenseCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </optgroup>

            <optgroup label="Income" className="bg-gray-200 dark:bg-gray-500">
              {incomeCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </optgroup>
          </select>
        </div>
      )}
    </>
  );
};

export default TransactionsFiltersCategory;
