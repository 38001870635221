import axios, { HttpStatusCode } from "axios";
import eventEmitter from "../../shared/EventEmitter";

const apiUrl = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  responseType: "json",
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

// Axios interceptor to handle 401 errors and refresh the access token
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      originalRequest.url.includes("/Auth/AuthUser") ||
      originalRequest._skipInterceptor
    ) {
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response?.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        // Add to queue if another refresh is in progress
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const response = await axiosInstance.post("/Auth/RefreshToken");
        const { accessToken } = response.data;

        // Set new access token to all failed requests
        axiosInstance.defaults.headers[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        processQueue(null, accessToken);
        isRefreshing = false;

        // Retry the original request
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        processQueue(refreshError, null);
        isRefreshing = false;
        return Promise.reject(refreshError);
      }
    }

    if (error.response?.status === 401) {
      await handleLogout();
    }

    return Promise.reject(error);
  }
);

const handleLogout = async () => {
  await axiosInstance.post("/Auth/LogoutUser");
  eventEmitter.emit("logout");
};

const makeApiCall = async (method, url, data) => {
  if (process.env.NODE_ENV === "development") {
    console.log(method, url);
  }

  try {
    switch (method) {
      case "GET":
        return await axiosInstance.get(url);
      case "POST":
        return await axiosInstance.post(url, data);
      case "PUT":
        return await axiosInstance.put(url, data);
      case "PATCH":
        return await axiosInstance.patch(url, data);
      case "DELETE":
        return await axiosInstance.delete(url);
      default:
        throw new Error(`Unsupported request method: ${method}`);
    }
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.error(`${method} ${url} failed:`, error);
    }

    if (error.response?.status === 403) {
      console.log("403 Forbidden: Access is denied.");
      return HttpStatusCode.Forbidden;
    }

    return Promise.reject(error);
  }
};

export { makeApiCall };
