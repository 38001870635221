import React, { useEffect, useState } from "react";
import useTransactionsStore from "../../../store/TransactionsStore";

const TransactionsFiltersTag = ({
  displayTransactions,
  timeFrameTransactions,
  setFilteredTransactionsTag,
}) => {
  const {
    loading,
    transactions,
    transactionTags,
    selectedTag,
    setSelectedTag,
    selectedTimeFrame,
    selectedCategory,
  } = useTransactionsStore();

  const getUsedTagIds = () => {
    const selection =
      selectedCategory === -1 ? timeFrameTransactions : displayTransactions;

    return new Set(
      selection.map((transaction) => transaction.transactionTagId)
    );
  };

  const [usedTagIds, setUsedTagIds] = useState(getUsedTagIds());

  const usedTags = transactionTags
    .filter((tag) => usedTagIds.has(tag.id))
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    setUsedTagIds(getUsedTagIds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayTransactions, selectedCategory]);

  useEffect(() => {
    filterTransactionsByTag(selectedTag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag, timeFrameTransactions, transactions]);

  const handleTagChange = (event) => {
    const tagId = parseInt(event.target.value, 10);
    setSelectedTag(tagId);
  };

  const filterTransactionsByTag = (id) => {
    if (id === -1) {
      setFilteredTransactionsTag(
        selectedTimeFrame === 3 ? transactions : timeFrameTransactions
      );
    } else {
      setFilteredTransactionsTag(
        (selectedTimeFrame === 3 ? transactions : timeFrameTransactions).filter(
          (transaction) => transaction.transactionTagId === id
        )
      );
    }
  };

  return (
    <>
      {transactionTags.length > 0 && (
        <div className="text-center mt-2 space-x-4">
          <select
            className="px-3 py-2 bg-gray-100 dark:bg-gray-400 rounded-full transition-transform transform hover:scale-105 cursor-pointer disabled:cursor-not-allowed"
            onChange={handleTagChange}
            value={selectedTag}
            disabled={loading}
          >
            <option value="-1">All tags</option>

            {usedTags.map((tag) => (
              <option key={tag.id} value={tag.id}>
                {tag.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};

export default TransactionsFiltersTag;
