import React, { useState, useEffect } from "react";
import { FaMoon, FaSun } from "react-icons/fa";

const DarkModeToggle = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const savedPreference = localStorage.getItem("theme");

    if (savedPreference) {
      setDarkMode(savedPreference === "dark");
    }

    document.documentElement.style.backgroundColor =
      savedPreference === "dark" ? "#111827" : "#ffffff";

    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("theme", !darkMode ? "dark" : "light");
  };

  return (
    <button
      onClick={toggleDarkMode}
      className={`rounded-full hover:opacity-80 mr-3`}
    >
      {darkMode ? <FaSun size={17} /> : <FaMoon size={15} />}
    </button>
  );
};

export default DarkModeToggle;
