import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa6";
import { useCookies } from "react-cookie";

const PageSelector = ({ menuHidden }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [cookies] = useCookies();

  const navigate = useNavigate();
  const location = useLocation();

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const toggleDropdown = () => {
    if (menuHidden) {
      navigate("/");
    } else {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const handleHomePress = () => {
    navigate("/");
    localStorage.setItem("lastPage", "");
  };

  const handleAssetsPress = () => {
    navigate("/assets");
    localStorage.setItem("lastPage", "assets");
  };

  const handleCryptocurrenciesPress = () => {
    navigate("/cryptos");
    localStorage.setItem("lastPage", "cryptos");
  };

  const handleTransactionsPress = () => {
    navigate("/transactions");
    localStorage.setItem("lastPage", "transactions");
  };

  const getTitle = () => {
    switch (localStorage.getItem("lastPage")) {
      case "transactions":
        return "Transactions";
      case "cryptos":
        return "Cryptos";
      case "assets":
        return "Assets";
      default:
        return "Wherepare";
    }
  };

  return (
    <>
      <div className="relative" ref={modalRef}>
        <button
          onClick={toggleDropdown}
          className="select-none flex cursor-pointer hover:opacity-85"
        >
          <img
            src="/images/logo512.png"
            alt="Wherepare Logo"
            className="self-center h-6 w-6 mr-2"
          />

          <span className="font-bold text-xl">{getTitle()}</span>

          {cookies.userId && (
            <FaChevronDown size={16} className="self-center ml-1" />
          )}
        </button>

        {isDropdownOpen && (
          <div className="absolute top-full right-0 left-0 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-300 rounded-lg shadow-lg w-36 z-50 mt-1">
            <button
              className={`block px-4 py-2 text-center w-full hover:bg-gray-100 dark:hover:bg-gray-700 rounded-t-lg ${
                location.pathname === "/" && "font-bold"
              }`}
              onClick={handleHomePress}
            >
              Home
            </button>

            <button
              className={`block px-4 py-2 text-center w-full hover:bg-gray-100 dark:hover:bg-gray-700 ${
                location.pathname === "/transactions" && "font-bold"
              }`}
              onClick={handleTransactionsPress}
            >
              Transactions
            </button>

            <button
              className={`block px-4 py-2 text-center w-full hover:bg-gray-100 dark:hover:bg-gray-700 ${
                location.pathname === "/cryptos" && "font-bold"
              }`}
              onClick={handleCryptocurrenciesPress}
            >
              Cryptos
            </button>

            <button
              className={`block px-4 py-2 text-center w-full hover:bg-gray-100 dark:hover:bg-gray-700 rounded-b-lg ${
                location.pathname === "/assets" && "font-bold"
              }`}
              onClick={handleAssetsPress}
            >
              Assets
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PageSelector;
