import React from "react";
import Modal from "../../Modal/Modal";
import useCurrentUserStore from "../../../store/CurrentUserStore";
import CryptoSettingsCryptoCurrencies from "./CryptoSettingsCryptoCurrencies";

const CryptoSettings = ({ onClose }) => {
  const { currentUser } = useCurrentUserStore();

  return (
    <Modal
      onClose={onClose}
      title={"Cryptotransactions Settings"}
      showFootnote={true}
    >
      <h2 className="text-xl font-bold mb-4 text-black dark:text-gray-300 text-center cursor-pointer">
        Cryptocurrencies
      </h2>

      <CryptoSettingsCryptoCurrencies currentUser={currentUser} />
    </Modal>
  );
};

export default CryptoSettings;
