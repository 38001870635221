import React from "react";
import {
  FaGem,
  FaMoneyBillWave,
  FaCar,
  FaCoffee,
  FaUtensils,
  FaBook,
  FaMusic,
  FaHome,
  FaPiggyBank,
  FaGift,
  FaShoppingCart,
  FaHeartbeat,
  FaPaintBrush,
  FaDog,
  FaTshirt,
  FaDumbbell,
  FaQuestionCircle,
  FaClipboardList,
  FaBus,
  FaPlane,
  FaBriefcase,
  FaChartLine,
  FaCoins,
  FaHandHoldingUsd,
  FaUserFriends,
  FaUmbrella,
  FaDollarSign,
  FaArrowUp,
  FaArrowDown,
  FaHeart,
  FaMotorcycle,
} from "react-icons/fa";

const TransactionsItemIcon = ({ item, isIncome }) => {
  switch (item.transactionCategory) {
    case "Beauty":
      return <FaGem size={20} />;
    case "Bills":
      return <FaMoneyBillWave size={20} />;
    case "Car":
      return <FaCar size={20} />;
    case "Coffee & Drinks":
      return <FaCoffee size={20} />;
    case "Eating out":
      return <FaUtensils size={20} />;
    case "Education":
      return <FaBook size={20} />;
    case "Entertainment":
      return <FaMusic size={20} />;
    case "Family":
      return <FaHeart size={20} />;
    case "Friends":
      return <FaUserFriends size={20} />;
    case "Gifts":
      return <FaGift size={20} />;
    case "Groceries":
      return <FaShoppingCart size={20} />;
    case "Healthcare":
      return <FaHeartbeat size={20} />;
    case "Hobbies":
      return <FaPaintBrush size={20} />;
    case "Home":
      return <FaHome size={20} />;
    case "Investments":
      return <FaPiggyBank size={20} />;
    case "Other":
      return <FaQuestionCircle size={20} />;
    case "Pets":
      return <FaDog size={20} />;
    case "Shopping":
      return <FaTshirt size={20} />;
    case "Sport":
      return <FaDumbbell size={20} />;
    case "Subscriptions":
      return <FaClipboardList size={20} />;
    case "Transport":
      return <FaBus size={20} />;
    case "Travel":
      return <FaPlane size={20} />;
    case "Work":
      return <FaBriefcase size={20} />;
    case "Business":
      return <FaChartLine size={20} />;
    case "Extra Income":
      return <FaHandHoldingUsd size={20} />;
    case "Insurance":
      return <FaUmbrella size={20} />;
    case "Loans":
      return <FaCoins size={20} />;
    case "Salary":
      return <FaDollarSign size={20} />;
    case "Motorcycle":
      return <FaMotorcycle size={20} />;
    default:
      return isIncome ? <FaArrowUp size={20} /> : <FaArrowDown size={20} />;
  }
};

export default TransactionsItemIcon;
