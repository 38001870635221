import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import Auth from "./pages/Auth/Auth";
import Home from "./pages/Home/Home";
import Transactions from "./pages/Transactions/Transactions";
import Assets from "./pages/Assets/Assets";
import VerifyEmail from "./pages/Verification/VerifyEmail";
import ResetPassword from "./pages/Verification/ResetPassword";
import ForgotPassword from "./pages/Verification/ForgotPassword";
import LogIn from "./pages/Auth/LogIn";
import SignUp from "./pages/Auth/SignUp";
import LogoutListener from "./shared/LogoutListener";
import Cryptocurrencies from "./pages/Crypto/Cryptocurrencies";

function App() {
  const [cookies] = useCookies();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    cookies.userId != null ? setLoggedIn(true) : setLoggedIn(false);
  }, [cookies.userId]);

  return (
    <>
      <Router>
        <Routes>
          {!loggedIn ? (
            <>
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<LogIn />} />
              <Route path="*" element={<Auth />} />
            </>
          ) : (
            <>
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/assets" element={<Assets />} />
              <Route path="/cryptos" element={<Cryptocurrencies />} />
              <Route path="*" element={<Home />} />
            </>
          )}
          <Route path="/verify-email" element={<VerifyEmail />} />
        </Routes>
      </Router>
      <LogoutListener />
    </>
  );
}

export default App;
