import React from "react";
import AssetsItemIcon from "./AssetsItemIcon";
import { format } from "date-fns";
import { formatMoney } from "../../../services/FormatService/FormatText";

const AssetsItem = ({ asset, assetType, onEdit }) => {
  const icon = <AssetsItemIcon item={assetType} />;

  const percentageDifference =
    ((asset.currentWorth - asset.purchasePrice) / asset.purchasePrice) * 100;

  const differenceColor =
    percentageDifference >= 0 ? "text-green-500" : "text-red-500";

  return (
    <div
      className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-md flex justify-between items-center mb-4"
      onClick={() => onEdit(asset)}
    >
      <div className="flex items-center">
        <div className="mr-4 text-4xl">{icon}</div>

        <div>
          <h3 className="text-lg font-semibold text-black dark:text-gray-300">
            {asset.name}
          </h3>
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
            {asset.description}
          </p>
          <p className="text-sm text-black dark:text-gray-300">
            Amount: <span className="font-semibold">{asset.amount}</span>
          </p>
          <p className="text-sm text-black dark:text-gray-300">
            {"Purchase Price: "}
            <span className="font-semibold">
              {formatMoney(asset.purchasePrice, 2)} €
            </span>
          </p>
          <p className="text-sm text-black dark:text-gray-300">
            {"Current Worth: "}
            <span className="font-semibold">
              {formatMoney(asset.currentWorth, 2)} €
            </span>
          </p>
          <p className="text-sm text-black dark:text-gray-300">
            {"Purchase Date: "}
            <span className="font-semibold">
              {format(new Date(asset.purchaseDate), "dd.MM.yyyy.")}
            </span>
          </p>
        </div>
      </div>

      <div>
        <div className={`text-lg font-bold ${differenceColor}`}>
          {formatMoney(percentageDifference, 2)}%
        </div>
      </div>
    </div>
  );
};

export default AssetsItem;
