import React, { useState, useEffect } from "react";
import useCryptoStore from "../../../store/CryptoStore";
import CryptoTransactionsGroup from "./CryptoTransactionsGroup";

const CryptoTransactions = ({ openModal }) => {
  const { loading, cryptoCurrencies, cryptoTransactions } = useCryptoStore();

  const [groupedTransactions, setGroupedTransactions] = useState([]);

  useEffect(() => {
    if (cryptoCurrencies && cryptoTransactions) {
      const grouped = cryptoCurrencies.map((currency) => {
        const transactions = cryptoTransactions.filter(
          (tx) => tx.cryptoCurrencyId === currency.id
        );

        const totalCryptoAmount = transactions.reduce(
          (total, tx) => total + tx.cryptoAmount,
          0
        );

        const totalPurchasePrice = transactions.reduce(
          (total, tx) => total + tx.fiatAmount,
          0
        );

        const totalCurrentValue =
          currency.price *
          transactions.reduce((total, tx) => total + tx.cryptoAmount, 0);

        return {
          currency,
          transactions,
          totalCryptoAmount,
          totalPurchasePrice,
          totalCurrentValue,
        };
      });

      const sortedGrouped = grouped.sort(
        (a, b) => b.totalCurrentValue - a.totalCurrentValue
      );

      setGroupedTransactions(sortedGrouped);
    }
  }, [cryptoCurrencies, cryptoTransactions]);

  return (
    <>
      {loading ? (
        <div className="text-center text-xl mt-8 text-black dark:text-gray-300">
          Loading...
        </div>
      ) : groupedTransactions?.length > 0 ? (
        groupedTransactions.map(
          ({
            currency,
            transactions,
            totalCryptoAmount,
            totalPurchasePrice,
            totalCurrentValue,
          }) =>
            transactions.length > 0 && (
              <CryptoTransactionsGroup
                key={currency.id}
                currency={currency}
                transactions={transactions}
                totalCryptoAmount={totalCryptoAmount}
                totalCurrentValue={totalCurrentValue}
                totalPurchasePrice={totalPurchasePrice}
                openModal={openModal}
              />
            )
        )
      ) : (
        <div className="text-center text-xl font-medium mt-10 text-black dark:text-gray-300">
          No transactions found, add some!
        </div>
      )}
    </>
  );
};

export default CryptoTransactions;
