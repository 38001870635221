import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { makeApiCall } from "../../services/ApiService/ApiCall";
import Header from "../../components/Header/Header";

const VerifyEmail = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const verificationToken = searchParams.get("verificationToken");

  const [isSuccessful, setIsSuccessful] = useState("");

  useEffect(() => {
    const verifyUserEmail = async () => {
      if (userId && verificationToken) {
        try {
          const requestUrl = `/Users/VerifyEmail/${userId}?verificationToken=${verificationToken}`;
          const result = await makeApiCall("POST", requestUrl, null);

          if (result.data === 200) {
            setIsSuccessful(true);
            localStorage.removeItem("currentUser");
          } else {
            setIsSuccessful(false);
          }
        } catch (error) {
          console.log(error);
          setIsSuccessful(false);
        }
      }
    };

    verifyUserEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, verificationToken]);

  return (
    <>
      <Header menuHidden={true} />

      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
          <h1
            className={`text-2xl font-semibold mb-4 ${
              isSuccessful === ""
                ? "text-gray-600"
                : isSuccessful
                ? "text-green-600"
                : "text-red-500"
            }`}
          >
            {isSuccessful === ""
              ? "Verifying your email..."
              : isSuccessful
              ? "Email successfully verified."
              : "Email couldn't be verified."}
          </h1>

          <p className="text-gray-500">
            {isSuccessful === ""
              ? "Please wait while we verify your email."
              : isSuccessful
              ? "You can now log in with your account."
              : "If the problem persists, please contact support."}
          </p>

          <button
            onClick={() => navigate("/")}
            className="bg-green-500 text-white px-4 py-2 mt-4 rounded text-base font-medium hover:bg-green-600"
          >
            Log In
          </button>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
