import React, { useState } from "react";
import { makeApiCall } from "../../services/ApiService/ApiCall";
import { validateEmailFormat } from "../../services/FormatService/FormatText";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: format(new Date(), "yyyy-MM-dd"),
    email: "",
    username: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSignUp = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    const { firstName, lastName, dateOfBirth, email, username, password } =
      formData;

    if (
      !firstName ||
      !lastName ||
      !dateOfBirth ||
      !email ||
      !username ||
      !password
    ) {
      alert("Please fill out all required fields");
      return;
    }

    if (!validateEmailFormat(email)) {
      alert("Please enter a valid email address");
      return;
    }

    setIsLoading(true);

    try {
      const requestUrl = `/Users/CreateUser`;
      const result = await makeApiCall("POST", requestUrl, {
        FirstName: firstName,
        LastName: lastName,
        DateOfBirth: dateOfBirth,
        Email: email,
        Username: username,
        Password: password,
      });

      if (result === 403) {
        alert("Username and/or email already taken!");
        setIsLoading(false);
        return;
      }

      if (result.data) {
        console.log("**Sign up Successful**");
        alert(
          "Sign up successful! You can log in now.\n\nPlease check your email to verify your email address."
        );
        navigate("/login");
      } else {
        console.error("Sign up error");
        alert("Sign up error");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Failed to register user:", error);
      setIsLoading(false);
    }
  };

  const checkFormData = () => {
    if (
      formData.firstName.length === 0 ||
      formData.lastName.length === 0 ||
      formData.dateOfBirth.length === 0 ||
      formData.email.length === 0 ||
      formData.username.length === 0 ||
      formData.password.length === 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50">
      <div>
        <form
          onSubmit={handleSignUp}
          className="flex flex-col items-center w-full max-w-3xl p-12 bg-white rounded-lg shadow-xl"
        >
          <h2 className="text-3xl font-semibold text-gray-800 mb-12">
            Become a member
          </h2>

          <div className="w-full mb-6">
            <label
              htmlFor="firstName"
              className="block text-gray-700 text-base font-medium mb-3"
            >
              First name:
            </label>
            <input
              type="text"
              id="firstName"
              value={formData.firstName}
              onChange={(e) => handleInputChange("firstName", e.target.value)}
              disabled={isLoading}
              required
              className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
              maxLength={255}
            />
          </div>

          <div className="w-full mb-6">
            <label
              htmlFor="lastName"
              className="block text-gray-700 text-base font-medium mb-3"
            >
              Last name:
            </label>
            <input
              type="text"
              id="lastName"
              value={formData.lastName}
              onChange={(e) => handleInputChange("lastName", e.target.value)}
              disabled={isLoading}
              required
              className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
              maxLength={255}
            />
          </div>

          <div className="w-full mb-6">
            <label
              htmlFor="dateOfBirth"
              className="block text-gray-700 text-base font-medium mb-3"
            >
              Date of Birth
            </label>
            <input
              type="date"
              id="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={(e) => handleInputChange("dateOfBirth", e.target.value)}
              disabled={isLoading}
              required
              className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
            />
          </div>

          <div className="w-full mb-6">
            <label
              htmlFor="email"
              className="block text-gray-700 text-base font-medium mb-3"
            >
              Email:
            </label>
            <input
              type="email"
              autoCapitalize="none"
              id="email"
              value={formData.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
              disabled={isLoading}
              required
              className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
              maxLength={255}
            />
          </div>

          <div className="w-full mb-6">
            <label
              htmlFor="username"
              className="block text-gray-700 text-base font-medium mb-3"
            >
              Username:
            </label>
            <input
              type="text"
              autoCapitalize="none"
              id="username"
              value={formData.username}
              onChange={(e) => handleInputChange("username", e.target.value)}
              disabled={isLoading}
              required
              className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
              maxLength={50}
            />
          </div>

          <div className="w-full mb-6">
            <label
              htmlFor="password"
              className="block text-gray-700 text-base font-medium mb-3"
            >
              Password:
            </label>
            <input
              type="password"
              autoCapitalize="none"
              id="password"
              value={formData.password}
              onChange={(e) => handleInputChange("password", e.target.value)}
              disabled={isLoading}
              required
              className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
              maxLength={128}
            />
          </div>

          <button
            type="submit"
            className={`py-3 px-5 rounded ${
              checkFormData()
                ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
          >
            {isLoading ? "Signing up..." : "Sign Up"}
          </button>
        </form>

        <div className="flex flex-col items-center mt-2 text-center">
          <div className="text-gray-600 mb-2">Already a member?</div>
          <button
            onClick={() => navigate("/login")}
            className="bg-green-500 text-white px-4 py-2 rounded text-base font-medium hover:bg-green-600"
          >
            Log In
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
