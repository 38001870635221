import React, { useState } from "react";
import { makeApiCall } from "../../services/ApiService/ApiCall";
import Header from "../../components/Header/Header";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (!email) {
      alert("Please enter your email!");
      return;
    }

    setIsLoading(true);

    try {
      const requestUrl = `/Users/ForgotPassword/${email}`;
      const result = await makeApiCall("POST", requestUrl);

      if (result?.status === 200) {
        alert("Password reset link has been sent to your email address.");
      } else if (result?.status === 401 || result?.status === 403) {
        alert(
          "Email not verified. Please verify your email address and then try again.\n\nVerification email has been (re)sent."
        );
      }

      setIsLoading(false);
    } catch (error) {
      console.log("Failed to reset password:", error);
      alert("Failed to reset password, please try again later.");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header menuHidden={true} />

      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <form
          onSubmit={handleForgotPassword}
          className="flex flex-col items-center w-full max-w-xl p-12 bg-white rounded-lg shadow-xl"
        >
          <h1 className={`text-2xl font-semibold mb-4 text-black`}>
            Enter your email address
          </h1>
          <div className="w-full mb-6">
            <label
              htmlFor="email"
              className="block text-gray-700 text-base font-medium mb-3"
            >
              Email:
            </label>
            <input
              type="text"
              autoCapitalize="none"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
              required
              className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
              maxLength={255}
            />
          </div>

          <button
            type="submit"
            className={`py-3 px-5 rounded ${
              email.length === 0
                ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
          >
            {isLoading ? "Please wait..." : "Reset Password"}
          </button>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
