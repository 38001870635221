const AssetsItemIcon = ({ item }) => {
  switch (item) {
    case "Collectibles":
      return "🖼️";
    case "Cryptocurrencies":
      return "💰";
    case "Derivates":
      return "🛢️";
    case "Intellectual Property":
      return "📜";
    case "Precious Metals":
      return "🪙";
    case "Real Estate":
      return "🏠";
    case "Retirement Accounts":
      return "📊";
    case "Stock Market":
      return "📈";
    default:
      return "💼";
  }
};

export default AssetsItemIcon;
