import React from "react";
import useTransactionsStore from "../../store/TransactionsStore";
import { FaPlus } from "react-icons/fa";

const AddButton = ({ openModal, icon, position }) => {
  const { loading } = useTransactionsStore();

  return (
    <div
      className={`fixed bottom-10 ${
        position === "left"
          ? "left-4"
          : position === "right"
          ? "right-4"
          : "left-1/2 transform -translate-x-1/2"
      }`}
    >
      <button
        onClick={() => openModal(null)}
        className="bg-blue-500 text-white w-16 h-16 rounded-full shadow-lg hover:bg-blue-600 disabled:cursor-not-allowed text-3xl flex items-center justify-center"
        disabled={loading}
      >
        {icon ? icon : <FaPlus size={24} />}
      </button>
    </div>
  );
};

export default AddButton;
