import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { makeApiCall } from "../../services/ApiService/ApiCall";
import Header from "../../components/Header/Header";

const ResetPassword = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const verificationToken = searchParams.get("verificationToken");

  const [resetAttempted, setResetAttempted] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    password: "",
    repeatPassword: "",
  });

  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const resetUserPassword = async () => {
    if (
      userId &&
      verificationToken &&
      formData.password.length > 0 &&
      formData.password === formData.repeatPassword
    ) {
      setIsLoading(true);
      setResetAttempted(true);
      try {
        const requestUrl = `/Users/ResetUserPassword/${userId}?verificationToken=${verificationToken}`;
        const result = await makeApiCall("POST", requestUrl, {
          NewPassword: formData.password,
        });

        if (result.data === 200) {
          setIsSuccessful(true);
          alert("Password changed successfully.");
        } else {
          setIsSuccessful(false);
        }
      } catch (error) {
        console.log(error);
        setIsSuccessful(false);
      }
    }
  };

  return (
    <>
      <Header menuHidden={true} />

      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
          <h1
            className={`text-2xl font-semibold mb-4 ${
              isSuccessful === ""
                ? "text-gray-600"
                : isSuccessful
                ? "text-green-600"
                : "text-red-500"
            }`}
          >
            {isSuccessful === ""
              ? "Reset password"
              : isSuccessful
              ? "Password changed successfully!"
              : "Password couldn't be changed. Please try again later."}
          </h1>

          <p className="text-gray-500 mb-4">
            {isSuccessful === ""
              ? "Please enter a new password."
              : isSuccessful
              ? "You can now log in with your account."
              : "If the problem persists, please contact support."}
          </p>

          {isSuccessful ? (
            <></>
          ) : (
            <>
              <div className="w-full mb-6">
                <label
                  htmlFor="password"
                  className="block text-gray-700 text-base font-medium mb-3"
                >
                  New Password:
                </label>
                <input
                  type="password"
                  autoCapitalize="none"
                  id="password"
                  value={formData.password}
                  onChange={(e) =>
                    handleInputChange("password", e.target.value)
                  }
                  disabled={isLoading}
                  required
                  className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
                  maxLength={128}
                />
              </div>

              <div className="w-full mb-6">
                <label
                  htmlFor="repeatPassword"
                  className="block text-gray-700 text-base font-medium mb-3"
                >
                  Repeat Password:
                </label>
                <input
                  type="password"
                  autoCapitalize="none"
                  id="repeatPassword"
                  value={formData.repeatPassword}
                  onChange={(e) =>
                    handleInputChange("repeatPassword", e.target.value)
                  }
                  disabled={isLoading}
                  required
                  className="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
                  maxLength={128}
                />
              </div>
            </>
          )}

          {resetAttempted ? (
            <button
              onClick={() => navigate("/")}
              className="bg-green-500 text-white px-4 py-2 mt-4 rounded text-base font-medium hover:bg-green-600"
            >
              Log In
            </button>
          ) : (
            <button
              onClick={() => resetUserPassword()}
              className="bg-green-500 text-white px-4 py-2 mt-4 rounded text-base font-medium hover:bg-green-600"
            >
              Reset Password
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
