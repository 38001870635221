import React, { useState } from "react";
import TransactionsItem from "./TransactionsItem";
import { format } from "date-fns";
import { formatMoney } from "../../../services/FormatService/FormatText";
import useTransactionsStore from "../../../store/TransactionsStore";

const TransactionsList = ({
  openModal,
  displayTransactions,
  selectedTimeFrame,
}) => {
  const { loading } = useTransactionsStore();

  const [allCollapsed, setAllCollapsed] = useState(false);
  const [collapsedDates, setCollapsedDates] = useState({});

  const toggleDate = (date) => {
    setCollapsedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  // Group transactions by date and calculate the sum for each date
  const groupTransactionsByDate = (displayTransactions) => {
    const groupedTransactions = displayTransactions.reduce(
      (groups, transaction) => {
        const date = format(
          new Date(transaction.transactionDate),
          "dd.MM.yyyy."
        );
        if (!groups[date]) {
          groups[date] = { transactions: [], total: 0 };
        }
        groups[date].transactions.push(transaction);
        groups[date].total += transaction.amount;
        return groups;
      },
      {}
    );

    // Sort transactions within each date group alphabetically by transactionCategory
    for (const date in groupedTransactions) {
      groupedTransactions[date].transactions.sort((a, b) =>
        a.transactionCategory.localeCompare(b.transactionCategory)
      );
    }

    return groupedTransactions;
  };

  // Divide transactions into past and upcoming
  const now = new Date();

  const pastTransactions =
    selectedTimeFrame === 1
      ? groupTransactionsByDate(
          displayTransactions.filter(
            (transaction) => new Date(transaction.transactionDate) <= now
          )
        )
      : groupTransactionsByDate(displayTransactions);

  const upcomingTransactions =
    selectedTimeFrame === 1
      ? groupTransactionsByDate(
          displayTransactions
            .filter(
              (transaction) => new Date(transaction.transactionDate) > now
            )
            .reverse()
        )
      : [];

  const upcomingTransactionsTotal = Object.values(upcomingTransactions).reduce(
    (accumulator, group) => accumulator + group.total,
    0
  );

  const isCollapsed = (date) => collapsedDates[date];

  const handleCollapseAll = () => {
    setAllCollapsed(!allCollapsed);

    const newCollapsedState = {};

    Object.keys(pastTransactions).forEach((date) => {
      newCollapsedState[date] = !allCollapsed;
    });

    // Collapse or expand all upcoming transactions
    Object.keys(upcomingTransactions).forEach((date) => {
      newCollapsedState[date] = !allCollapsed;
    });

    setCollapsedDates(newCollapsedState);
  };

  return (
    <div className="p-5">
      {loading ? (
        <div className="text-center text-xl mt-8 text-black dark:text-gray-300">
          Loading...
        </div>
      ) : Object.keys(pastTransactions).length > 0 ||
        Object.keys(upcomingTransactions).length > 0 ? (
        <>
          <div
            className="text-end text-sm text-gray-600 dark:text-gray-400 cursor-pointer"
            onClick={handleCollapseAll}
          >
            {allCollapsed ? "Expand all" : "Collapse all"}
          </div>

          {/* Display Past Transactions */}
          {Object.keys(pastTransactions).map((date) => (
            <div key={date} className="mb-8">
              <h3
                className="text-xl font-semibold mb-4 flex items-center justify-between text-black dark:text-gray-300 cursor-pointer"
                onClick={() => toggleDate(date)}
              >
                {date}
                <span
                  className={`text-base font-light ${
                    pastTransactions[date].total >= 0
                      ? "text-green-600"
                      : "text-red-500"
                  }`}
                >
                  {formatMoney(pastTransactions[date].total, 2)} €
                </span>
              </h3>

              {!isCollapsed(date) &&
                pastTransactions[date].transactions.map((transaction) => (
                  <TransactionsItem
                    item={transaction}
                    key={transaction.id}
                    onClick={() => openModal(transaction)}
                  />
                ))}
            </div>
          ))}

          {/* Display Upcoming Transactions (if any) */}
          {Object.keys(upcomingTransactions).length > 0 && (
            <div className="mt-12">
              <h3 className="text-xl font-base mb-2 flex items-center justify-between text-black dark:text-gray-300">
                Upcoming transactions
                <span
                  className={`text-base font-light ${
                    upcomingTransactionsTotal >= 0
                      ? "text-green-600"
                      : "text-red-500"
                  }`}
                >
                  {formatMoney(upcomingTransactionsTotal, 2)} €
                </span>
              </h3>
              <hr className="mb-2" />
              {Object.keys(upcomingTransactions).map((date) => (
                <div key={date} className="mb-8">
                  <h3
                    className="text-xl font-semibold mb-4 flex items-center justify-between text-black dark:text-gray-300 cursor-pointer"
                    onClick={() => toggleDate(date)}
                  >
                    {date}
                    <span
                      className={`text-base font-light ${
                        upcomingTransactions[date].total >= 0
                          ? "text-green-600"
                          : "text-red-500"
                      }`}
                    >
                      {formatMoney(upcomingTransactions[date].total, 2)} €
                    </span>
                  </h3>

                  {!isCollapsed(date) &&
                    upcomingTransactions[date].transactions.map(
                      (transaction) => (
                        <TransactionsItem
                          item={transaction}
                          key={transaction.id}
                          onClick={() => openModal(transaction)}
                        />
                      )
                    )}
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <div className="text-center text-xl font-medium mt-10 text-black dark:text-gray-300">
          No transactions found, add some!
        </div>
      )}
    </div>
  );
};

export default TransactionsList;
