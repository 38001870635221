import React from "react";
import TransactionsItemIcon from "./TransactionsItemIcon";
import { formatMoney } from "../../../services/FormatService/FormatText";

const TransactionsItem = ({ item, onClick }) => {
  const isIncome = item.amount > 0;

  return (
    <div
      className={`flex items-center justify-between p-4 rounded-lg shadow-md cursor-pointer mb-4 mx-2 transition-transform transform ${
        isIncome
          ? "bg-green-50 dark:bg-green-900/40 border-l-4 border-green-600 hover:bg-green-100 dark:hover:bg-green-800/50"
          : "bg-red-50 dark:bg-red-950/40 border-l-4 border-red-500 hover:bg-red-100 dark:hover:bg-red-900/40"
      }`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <div
          className={`p-2 rounded-full ${
            isIncome ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"
          }`}
        >
          <TransactionsItemIcon item={item} isIncome={isIncome} />
        </div>

        <div className="ml-4">
          <p className="text-lg font-semibold text-gray-800 dark:text-gray-300">
            {item.transactionCategory}
          </p>

          <p className="text-sm text-gray-600 dark:text-gray-400">
            {item.description}
          </p>

          <p className="text-sm text-gray-500">{item.transactionTag}</p>
        </div>
      </div>

      <div className="text-right">
        <p
          className={`text-xl font-bold ${
            isIncome ? "text-green-600" : "text-red-500"
          }`}
        >
          {formatMoney(item.amount, 2)} €
        </p>
        {item.transactionAccount && (
          <p className="text-sm text-gray-500">{item.transactionAccount}</p>
        )}
      </div>
    </div>
  );
};

export default TransactionsItem;
