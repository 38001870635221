import { useCookies } from "react-cookie";
import { makeApiCall } from "../ApiService/ApiCall";

const useTransactionsData = () => {
  const [cookies] = useCookies();

  // GET

  const getTransactions = async (
    categories,
    accounts,
    tags,
    selectedCategory
  ) => {
    if (!categories) {
      categories = await getTransactionCategories();
    }
    if (!accounts) {
      accounts = await getTransactionAccounts();
    }
    if (!tags) {
      tags = await getTransactionTags();
    }
    if (!selectedCategory) {
      selectedCategory = -1;
    }

    const requestUrl = `/Transactions/GetTransactions/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    result.data.forEach((element) => {
      const category = categories.find(
        (c) => c.id === element.transactionCategoryId
      );
      element.transactionCategory = category
        ? category.name
        : "Unknown Category";

      const account = accounts.find(
        (a) => a.id === element.transactionAccountId
      );
      element.transactionAccount = account?.name;

      const tag = tags.find((a) => a.id === element.transactionTagId);
      element.transactionTag = tag?.name;
    });

    return result.data;
  };

  const getTransactionCategories = async () => {
    const requestUrl = `/Transactions/GetTransactionCategories/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    const apiCategories = result.data.map((element) => ({
      id: element.id,
      name: element.name,
      description: element.description,
      isIncome: element.income,
      userId: element.userId,
    }));

    return apiCategories;
  };

  const getTransactionAccounts = async () => {
    const requestUrl = `/Transactions/GetTransactionAccounts/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    const apiCategories = result.data.map((element) => ({
      id: element.id,
      name: element.name,
      description: element.description,
      userId: element.userId,
    }));

    return apiCategories;
  };

  const getTransactionTags = async () => {
    const requestUrl = `/Transactions/GetTransactionTags/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    const apiCategories = result.data.map((element) => ({
      id: element.id,
      name: element.name,
      description: element.description,
      userId: element.userId,
    }));

    return apiCategories;
  };

  const getRepeatIntervals = async () => {
    const requestUrl = `/Transactions/GetRepeatIntervals`;
    const result = await makeApiCall("GET", requestUrl, null);

    const apiRepeatIntervals = result.data.map((element) => ({
      id: element.id,
      name: element.name,
    }));

    return apiRepeatIntervals;
  };

  // POST

  const createTransaction = async (item) => {
    let requestUrl = `/Transactions/CreateTransaction/${cookies.userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Amount: item.amount,
      Description: item.description,
      TransactionCategoryId: item.category,
      TransactionAccountId: item.account,
      TransactionTagId: item.tag,
      TransactionDate: item.date,
      RepeatIntervalId: 1, // hardcoded for now
    });

    return result;
  };

  const createTransactionCategory = async (item) => {
    let requestUrl = `/Transactions/CreateTransactionCategory/${cookies.userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Name: item.name,
      Description: item.description,
      IsIncome: item.isIncome,
    });

    return result;
  };

  const createTransactionAccount = async (item) => {
    let requestUrl = `/Transactions/CreateTransactionAccount/${cookies.userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Name: item.name,
      Description: item.description,
    });

    return result;
  };

  const createTransactionTag = async (item) => {
    let requestUrl = `/Transactions/CreateTransactionTag/${cookies.userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Name: item.name,
      Description: item.description,
    });

    return result;
  };

  const exportTransactions = async (item) => {
    let requestUrl = `/Transactions/ExportTransactions/${cookies.userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Name: item.name,
      Email: item.email,
    });

    return result;
  };

  // PUT

  const updateTransaction = async (item) => {
    let requestUrl = `/Transactions/UpdateTransaction/${item.id}`;
    let apiMethod = "PUT";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Amount: item.amount,
      Description: item.description,
      TransactionCategoryId: item.category,
      TransactionAccountId: item.account,
      TransactionTagId: item.tag,
      TransactionDate: item.date,
      RepeatIntervalId: 1, // hardcoded for now
    });

    return result;
  };

  const updateTransactionCategory = async (item) => {
    let requestUrl = `/Transactions/UpdateTransactionCategory/${item.userId}/${item.id}`;
    let apiMethod = "PUT";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Name: item.name,
      Description: item.description,
      IsIncome: item.isIncome,
    });

    return result;
  };

  const updateTransactionAccount = async (item) => {
    let requestUrl = `/Transactions/UpdateTransactionAccount/${item.userId}/${item.id}`;
    let apiMethod = "PUT";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Name: item.name,
      Description: item.description,
    });

    return result;
  };

  const updateTransactionTag = async (item) => {
    let requestUrl = `/Transactions/UpdateTransactionTag/${item.userId}/${item.id}`;
    let apiMethod = "PUT";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Name: item.name,
      Description: item.description,
    });

    return result;
  };

  // DELETE

  const deleteTransaction = async (item) => {
    const requestUrl = `/Transactions/DeleteTransaction/${item.id}`;
    let apiMethod = "DELETE";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  const deleteTransactionCategory = async (item) => {
    const requestUrl = `/Transactions/DeleteTransactionCategory/${item.userId}/${item.categoryId}`;
    let apiMethod = "DELETE";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  const deleteTransactionAccount = async (item) => {
    const requestUrl = `/Transactions/DeleteTransactionAccount/${item.userId}/${item.categoryId}`;
    let apiMethod = "DELETE";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  const deleteTransactionTag = async (item) => {
    const requestUrl = `/Transactions/DeleteTransactionTag/${item.userId}/${item.categoryId}`;
    let apiMethod = "DELETE";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  return {
    getTransactions,
    getTransactionCategories,
    getTransactionAccounts,
    getTransactionTags,
    getRepeatIntervals,
    createTransaction,
    createTransactionCategory,
    createTransactionAccount,
    createTransactionTag,
    exportTransactions,
    updateTransaction,
    updateTransactionCategory,
    updateTransactionAccount,
    updateTransactionTag,
    deleteTransaction,
    deleteTransactionCategory,
    deleteTransactionAccount,
    deleteTransactionTag,
  };
};

export default useTransactionsData;
