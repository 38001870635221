import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import Header from "../../components/Header/Header";
import Body from "../../components/Body/Body";
import { formatMoney } from "../../services/FormatService/FormatText";
import useTransactionsStore from "../../store/TransactionsStore";
import useAssetsStore from "../../store/AssetsStore";
import useCryptoStore from "../../store/CryptoStore";
import useTransactionsLogic from "../../hooks/TransactionsLogic";
import useAssetsLogic from "../../hooks/AssetsLogic";
import useCryptoLogic from "../../hooks/CryptoLogic";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const Home = () => {
  const [page] = useState(localStorage.getItem("lastPage"));

  const { transactions } = useTransactionsStore();
  const { setRefreshTransactions } = useTransactionsLogic();

  const { assets } = useAssetsStore();
  const { setRefreshAssets } = useAssetsLogic();

  const { cryptoCurrencies, cryptoTransactions } = useCryptoStore();
  const { setRefreshCrypto } = useCryptoLogic();

  const [overviewData, setOverviewData] = useState({
    balance: 0,
    cryptoWorth: 0,
    assetWorth: 0,
  });

  const navigate = useNavigate();

  const calculateGoal = (netWorth) => {
    if (netWorth < 1000) return 1000;
    if (netWorth < 5000) return 5000;
    if (netWorth < 10000) return 10000;
    if (netWorth < 25000) return 25000;
    if (netWorth < 50000) return 50000;
    if (netWorth < 100000) return 100000;
    if (netWorth < 250000) return 250000;
    if (netWorth < 500000) return 500000;
    if (netWorth < 750000) return 750000;
    return 1000000;
  };

  const totalNetWorth =
    overviewData.balance + overviewData.assetWorth + overviewData.cryptoWorth;
  const goal = calculateGoal(totalNetWorth);

  const percentage = Math.min((totalNetWorth / goal) * 100, 100);

  useEffect(() => {
    const possiblePages = ["assets", "cryptos", "transactions"];

    if (page && possiblePages.includes(page)) {
      navigate(`/${page}`);
    } else {
      navigate("/");
      localStorage.setItem("lastPage", "");
    }
  }, [page, navigate]);

  useEffect(() => {
    setRefreshTransactions(true);
    setRefreshAssets(true);
    setRefreshCrypto(true);
  }, [setRefreshTransactions, setRefreshAssets, setRefreshCrypto]);

  useEffect(() => {
    if (transactions.length || assets.length || cryptoTransactions.length) {
      const balance = transactions
        .filter(
          (transaction) => new Date(transaction.transactionDate) <= new Date()
        )
        .reduce(
          (accumulator, transaction) => accumulator + transaction.amount,
          0
        );

      const cryptoWorth = cryptoTransactions.reduce((total, transaction) => {
        const currency = cryptoCurrencies.find(
          (currency) => currency.id === transaction.cryptoCurrencyId
        );
        return (
          total + transaction.cryptoAmount * (currency ? currency.price : 0)
        );
      }, 0);

      const assetWorth = assets.reduce(
        (total, asset) => total + asset.currentWorth,
        0
      );

      setOverviewData({
        balance,
        cryptoWorth,
        assetWorth,
      });
    }
  }, [transactions, assets, cryptoCurrencies, cryptoTransactions]);

  // Chart data and options
  const chartData = {
    labels: ["Balance", "Cryptos", "Assets"],
    datasets: [
      {
        data: [
          overviewData.balance,
          overviewData.cryptoWorth,
          overviewData.assetWorth,
        ],
        backgroundColor: ["#3b82f6", "#10b981", "#f59e0b"],
        borderColor: "#ffffff",
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#6b7280",
          font: {
            size: 16,
          },
          padding: 25,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${formatMoney(tooltipItem.raw)} €`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  if (page) return null;

  return (
    <>
      <Header />
      <Body>
        {/* Net Worth Progress */}
        <div className="w-full flex flex-col justify-center items-center mt-4">
          <h2 className="text-xl font-semibold text-center text-black dark:text-gray-300 mb-2">
            Net Worth Progress
          </h2>

          <div className="w-full max-w-sm md:max-w-2xl bg-gray-200 dark:bg-gray-700 rounded-full h-6 mt-2 relative">
            <div
              className="bg-blue-500 h-6 rounded-full"
              style={{
                width: `${percentage}%`,
              }}
            />
            <div className="absolute inset-0 flex justify-center items-center text-white text-sm font-semibold">
              {percentage.toFixed(2)}%
            </div>
          </div>

          <p className="text-sm text-center text-gray-500 dark:text-gray-400 mt-2">
            Goal: {formatMoney(goal, 2)} €
          </p>
        </div>

        <hr className="mt-5" />

        {/* Current Stats */}
        <div className="mt-5">
          <h2 className="text-xl font-semibold text-center text-black dark:text-gray-300 mb-2">
            Current Statistics
          </h2>

          <h3 className="text-xl text-center mt-4 text-black dark:text-gray-300">
            {"Liquidity: "}
            <span className="font-bold">
              {formatMoney(overviewData.balance, 2)} €
            </span>
          </h3>

          <h3 className="text-xl text-center mt-4 text-black dark:text-gray-300">
            {"Investments: "}
            <span className="font-bold">
              {formatMoney(
                overviewData.assetWorth + overviewData.cryptoWorth,
                2
              )}{" "}
              €
            </span>
            <span className="block text-sm text-gray-500 dark:text-gray-400 mt-1">
              {`(Crypto: ${formatMoney(
                overviewData.cryptoWorth,
                2
              )} €, Assets: ${formatMoney(overviewData.assetWorth, 2)} €)`}
            </span>
          </h3>

          <div className="flex justify-center items-center h-96 mt-8">
            <div className="w-full max-w-lg mx-auto h-[400px]">
              <Pie data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </Body>
    </>
  );
};

export default Home;
