import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTransition = ({ children, isModal }) => {
  const [isMounted, setIsMounted] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsMounted(true);
  }, [location]);

  return (
    <div
      className={`transition-all ${
        isModal ? "duration-100" : "duration-200"
      } ease-in-out transform ${
        isMounted ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
      }`}
    >
      {children}
    </div>
  );
};

export default PageTransition;
