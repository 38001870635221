import { useCookies } from "react-cookie";
import { makeApiCall } from "../ApiService/ApiCall";

const useCurrentUserData = () => {
  const [cookies, , removeCookie] = useCookies();

  // GET

  const getUser = async () => {
    const requestUrl = `/Users/GetUser/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  const getUserInfo = async () => {
    const requestUrl = `/Users/GetUserInfo/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  const getUserAddress = async () => {
    const requestUrl = `/Users/GetUserAddress/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  // POST

  const logoutUser = async () => {
    removeCookie("userId", {
      path: "/",
      sameSite: "none",
      secure: true,
    });

    const requestUrl = `/Auth/LogoutUser`;
    const result = await makeApiCall("POST", requestUrl, null);

    return result;
  };

  // PUT

  const updateUser = async (item) => {
    const requestUrl = `/Users/UpdateUser/${cookies.userId}`;

    const result = await makeApiCall("PUT", requestUrl, {
      Username: item.username,
      Email: item.email,
    });

    return result;
  };

  const updateUserInfo = async (item) => {
    const requestUrl = `/Users/UpdateUserInfo/${cookies.userId}`;

    const result = await makeApiCall("PUT", requestUrl, {
      FirstName: item.firstName,
      LastName: item.lastName,
      DateOfBirth: item.dateOfBirth,
    });

    return result;
  };

  const updateUserPassword = async (item) => {
    const requestUrl = `/Users/UpdateUserPassword/${cookies.userId}`;

    const result = await makeApiCall("PUT", requestUrl, {
      CurrentPassword: item.currentPassword,
      NewPassword: item.newPassword,
    });

    return result;
  };

  return {
    getUser,
    getUserInfo,
    getUserAddress,
    updateUserPassword,
    logoutUser,
    updateUser,
    updateUserInfo,
  };
};

export default useCurrentUserData;
