import React, { useState, useEffect } from "react";
import useAssetsStore from "../../store/AssetsStore";
import Modal from "../Modal/Modal";
import Label from "../Modal/Label";
import { format } from "date-fns";

const AssetsModal = ({ onSave, onDelete, onClose, initialData }) => {
  const { assetTypes } = useAssetsStore();

  const [formState, setFormState] = useState({
    name: "",
    description: "",
    purchasePrice: "",
    amount: "",
    currentWorth: "",
    purchaseDate: format(new Date(), "yyyy-MM-dd"),
    assetTypeId: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormState({
        ...initialData,
        purchaseDate: format(new Date(initialData.purchaseDate), "yyyy-MM-dd"),
      });
    }
  }, [initialData]);

  const handleNumberInput = (value, allowNegative, decimalPoints) => {
    const regex = allowNegative ? /^-?\d*[.,]?\d*$/ : /^\d*[.,]?\d*$/;

    if (regex.test(value)) {
      let processedValue = value.replace(",", ".");

      if (decimalPoints !== null) {
        const [integerPart, decimalPart] = processedValue.split(".");
        if (decimalPart && decimalPart.length > decimalPoints) {
          processedValue = `${integerPart}.${decimalPart.substring(
            0,
            decimalPoints
          )}`;
        }
      }

      return processedValue;
    }
    return null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
      const processedValue = handleNumberInput(value, true, null);
      if (processedValue !== null) {
        setFormState((prev) => ({ ...prev, amount: processedValue }));
      }
    } else if (name === "purchasePrice" || name === "currentWorth") {
      const processedValue = handleNumberInput(value, true, 2);
      if (processedValue !== null) {
        setFormState((prev) => ({ ...prev, [name]: processedValue }));
      }
    } else {
      setFormState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const assetToSave = {
      ...formState,
      purchasePrice:
        formState.purchasePrice !== ""
          ? parseFloat(formState.purchasePrice)
          : 0,
      currentWorth:
        formState.currentWorth !== "" ? parseFloat(formState.currentWorth) : 0,
      amount: formState.amount !== "" ? parseFloat(formState.amount) : 0,
      purchaseDate: new Date(formState.purchaseDate).toISOString(),
    };
    onSave(assetToSave);
  };

  const handleDelete = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this asset?"
    );

    if (isConfirmed) {
      onDelete(initialData);
      onClose();
    }
  };

  const handleCancel = () => {
    let showDialog = false;

    if (initialData) {
      const { purchaseDate, assetTypeId, ...fieldsToCheck } = formState;

      const hasChanges = Object.keys(fieldsToCheck).some(
        (key) => fieldsToCheck[key] !== initialData[key]
      );

      if (hasChanges) {
        showDialog = true;
      }
    } else {
      const { purchaseDate, assetTypeId, ...fieldsToCheck } = formState;

      const hasData = Object.values(fieldsToCheck).some(
        (value) => value !== "" && value !== null
      );

      if (hasData) {
        showDialog = true;
      }
    }

    if (showDialog) {
      const isConfirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to cancel?"
      );

      if (isConfirmed) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal
      onClose={handleCancel}
      title={initialData ? "Edit Asset" : "Add Asset"}
      showFootnote={true}
    >
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <Label text={"Name"} isRequired={true} />
          <input
            type="text"
            name="name"
            value={formState.name}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
            placeholder="Enter asset name"
            maxLength={50}
            required
          />
          <div className="text-right text-sm text-gray-500">
            {formState.name.length}/{50} characters
          </div>
        </div>

        <div className="mb-2">
          <Label text={"Description"} />
          <textarea
            name="description"
            value={formState.description}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
            placeholder="Enter asset description"
            maxLength={255}
          />
          <div className="text-right text-sm text-gray-500">
            {formState.description.length}/{255} characters
          </div>
        </div>

        <div className="mb-4">
          <Label text={"Amount"} />
          <input
            type="tel"
            inputMode="decimal"
            name="amount"
            value={formState.amount}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
            placeholder="Enter amount"
          />
        </div>

        <div className="mb-4">
          <Label text={"Purchase Price (€)"} isRequired={true} />
          <input
            type="tel"
            inputMode="decimal"
            name="purchasePrice"
            value={formState.purchasePrice}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
            placeholder="Enter purchase price"
            required
          />
        </div>

        <div className="mb-4">
          <Label text={"Current Worth (€)"} />
          <input
            type="tel"
            inputMode="decimal"
            name="currentWorth"
            value={formState.currentWorth}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
            placeholder="Enter current worth"
          />
        </div>

        <div className="mb-4">
          <Label text={"Purchase Date"} isRequired={true} />
          <input
            type="date"
            name="purchaseDate"
            value={formState.purchaseDate}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-white dark:bg-gray-300"
            required
          />
        </div>

        <div className="mb-4">
          <Label text={"Asset Type"} isRequired={true} />
          <select
            name="assetTypeId"
            value={formState.assetTypeId}
            onChange={handleChange}
            className="w-full p-2 rounded mt-1 border bg-gray-50 dark:bg-gray-300"
            required
          >
            <option value="" disabled>
              Select an Asset Type
            </option>
            {assetTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex mt-6 mb-3">
          {initialData && (
            <button
              onClick={handleDelete}
              className="mr-2 bg-red-400 py-3 px-5 rounded hover:bg-red-500"
            >
              Delete
            </button>
          )}

          <div className="ml-auto flex">
            <button
              type="button"
              onClick={handleCancel}
              className="mr-2 bg-gray-300 py-3 px-5 rounded hover:bg-gray-400"
            >
              Cancel
            </button>

            <button
              type="submit"
              className={`py-3 px-5 rounded bg-blue-500 text-white hover:bg-blue-600 disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed`}
              disabled={
                formState.purchasePrice.length === 0 ||
                formState.name.length === 0 ||
                formState.assetTypeId === ""
              }
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AssetsModal;
