import React from "react";
import Modal from "../../../Modal/Modal";
import useAppLogic from "../../../../hooks/AppLogic";

const Settings = ({ onClose }) => {
  const { clearAppCache } = useAppLogic();

  const handleClearAppCache = () => {
    clearAppCache(false);
    onClose();
  };

  return (
    <Modal onClose={onClose} title={"Application Settings"}>
      <button
        onClick={() => handleClearAppCache()}
        className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600"
      >
        Clear Local Cache
      </button>
    </Modal>
  );
};

export default Settings;
