import React from "react";
import Modal from "../../Modal/Modal";
import useCurrentUserStore from "../../../store/CurrentUserStore";
import AssetsSettingsAssetTypes from "./AssetsSettingsAssetTypes";

const AssetsSettings = ({ onClose }) => {
  const { currentUser } = useCurrentUserStore();

  return (
    <Modal onClose={onClose} title={"Assets Settings"} showFootnote={true}>
      <h2 className="text-xl font-bold mb-4 text-black dark:text-gray-300 text-center cursor-pointer">
        Asset Types
      </h2>

      <AssetsSettingsAssetTypes currentUser={currentUser} />
    </Modal>
  );
};

export default AssetsSettings;
