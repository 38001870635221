import React from "react";
import AssetsItemIcon from "./AssetsItemIcon";
import { formatMoney } from "../../../services/FormatService/FormatText";

const AssetsTypeSummary = ({
  assetTypeName,
  totalPurchasePrice,
  totalCurrentWorth,
}) => {
  const icon = <AssetsItemIcon item={assetTypeName} />;

  const percentageDifference =
    ((totalCurrentWorth - totalPurchasePrice) / totalPurchasePrice) * 100;

  const differenceColor =
    percentageDifference >= 0 ? "text-green-500" : "text-red-500";

  return (
    <div className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-md flex justify-between items-center hover:opacity-80">
      <div className="flex items-center">
        <div className="mr-4 text-4xl">{icon}</div>
        <div>
          <h3 className="text-lg font-semibold text-black dark:text-gray-300">
            {assetTypeName}
            <span className="text-xs font-normal">{" (expand)"}</span>
          </h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {"Total Purchase Price: "}
            <span className="font-bold">
              {formatMoney(totalPurchasePrice, 2)} €
            </span>
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {"Total Current Worth: "}
            <span className="font-bold">
              {formatMoney(totalCurrentWorth, 2)} €
            </span>
          </p>
        </div>
      </div>
      <div className={`text-lg font-bold ${differenceColor}`}>
        {formatMoney(percentageDifference, 2)}%
      </div>
    </div>
  );
};

export default AssetsTypeSummary;
