import React, { useState } from "react";
import useAssetsLogic from "../../../hooks/AssetsLogic";
import useAssetsStore from "../../../store/AssetsStore";
import Label from "../../Modal/Label";

const AssetsSettingsAssetTypes = ({ currentUser }) => {
  const { addAssetType, editAssetType, removeAssetType } = useAssetsLogic();

  const { assetTypes, loading } = useAssetsStore();

  const [newAssetType, setNewAssetType] = useState({
    id: null,
    name: "",
    description: "",
  });

  const [editingId, setEditingId] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAssetType((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (editingId === null) {
      handleAddAssetType();
    } else {
      handleEditAssetType();
    }

    resetForm();
  };

  const handleAddAssetType = async () => {
    const result = await addAssetType(newAssetType);
    if (result) {
      alert("An error occured. Please try again.");
    }
  };

  const handleEditAssetType = async () => {
    const result = await editAssetType(newAssetType);
    if (result) {
      alert("An error occured. Please try again.");
    }
  };

  const handleEdit = (category) => {
    setNewAssetType(category);
    setEditingId(category.id);
  };

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this asset type?"
    );
    if (isConfirmed) {
      const category = { userId: currentUser.id, id: id };
      const result = await removeAssetType(category);

      if (result) {
        alert(
          "Asset type couldn't be deleted. Please try again.\n\nTip: Only unused asset types can be deleted."
        );
      }
    }
  };

  const cancelEdit = () => {
    resetForm();
  };

  const resetForm = () => {
    setNewAssetType({
      id: null,
      name: "",
      description: "",
    });
    setEditingId(null);
  };

  return (
    <div className="space-y-4">
      <label className="block text-gray-700 dark:text-gray-300 font-semibold text-center">
        All AssetTypes
      </label>

      <div className="border p-4 mt-4 min-h-64 max-h-64 overflow-y-auto space-y-4 bg-gray-50 dark:bg-gray-300 rounded">
        {loading ? (
          <div className="text-center text-base mt-4 text-black dark:text-gray-300">
            Loading...
          </div>
        ) : (
          <>
            {assetTypes.length > 0 ? (
              assetTypes.map((category) => (
                <div
                  key={category.id}
                  className="flex justify-between items-center border-b pb-2"
                >
                  <div>
                    <h3 className="font-bold text-gray-800">{category.name}</h3>

                    <p className="text-gray-600">{category.description}</p>
                  </div>

                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEdit(category)}
                      className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(category.id)}
                      className="bg-red-400 text-white py-1 px-3 rounded hover:bg-red-500"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-base mt-4 text-black dark:text-gray-300">
                No asset types
              </div>
            )}
          </>
        )}
      </div>

      <label className="block text-gray-700 dark:text-gray-300 font-semibold text-center">
        {editingId === null ? "Add New AssetType" : "Update AssetType"}
      </label>

      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <Label text={"Name"} isRequired={true} />
          <input
            type="text"
            id="name"
            name="name"
            value={newAssetType.name}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mt-1"
            placeholder="Enter name"
            maxLength={50}
            required
          />
          <div className="text-right text-sm text-gray-500">
            {newAssetType.name.length}/{50} characters
          </div>
        </div>

        <div className="mb-2">
          <Label text={"Description"} />
          <textarea
            type="text"
            id="description"
            name="description"
            value={newAssetType.description}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mt-1"
            placeholder="Enter description"
            maxLength={255}
          />
          <div className="text-right text-sm text-gray-500">
            {newAssetType.description.length}/{255} characters
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          {editingId !== null && (
            <button
              type="button"
              onClick={cancelEdit}
              className="bg-gray-300 py-2 px-4 mb-2 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
          )}

          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 mb-2 rounded hover:bg-blue-600 disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed"
            disabled={newAssetType.name.length === 0}
          >
            {editingId === null ? "Add Asset type" : "Update Asset type"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AssetsSettingsAssetTypes;
