import { useCookies } from "react-cookie";
import { makeApiCall } from "../ApiService/ApiCall";

const useCryptoData = () => {
  const [cookies] = useCookies();

  // GET

  const getCryptoCurrencies = async () => {
    const requestUrl = `/Crypto/GetCryptoCurrencies/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    const apiCryptoCurrencies = result.data.map((element) => ({
      id: element.id,
      ticker: element.ticker,
      name: element.name,
      description: element.description,
      price: element.price,
    }));

    return apiCryptoCurrencies;
  };

  const getCryptoTransactions = async (cryptoCurrencies) => {
    if (!cryptoCurrencies) {
      cryptoCurrencies = await getCryptoCurrencies();
    }

    const requestUrl = `/Crypto/GetCryptoTransactions/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  // POST

  const createCryptoCurrency = async (item) => {
    let requestUrl = `/Crypto/CreateCryptoCurrency/${cookies.userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Ticker: item.ticker,
      Name: item.name,
      Description: item.description,
      Price: item.price,
    });

    return result;
  };

  const createCryptoTransaction = async (item) => {
    let requestUrl = `/Crypto/CreateCryptoTransaction/${cookies.userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      CryptoCurrencyId: item.cryptoCurrencyId,
      CryptoAmount: item.cryptoAmount,
      FiatCurrency: item.fiatCurrency,
      FiatAmount: item.fiatAmount,
      Description: item.description,
      TransactionDate: item.transactionDate,
    });

    return result;
  };

  // PUT

  const updateCryptoCurrency = async (item) => {
    let requestUrl = `/Crypto/UpdateCryptoCurrency/${cookies.userId}/${item.id}`;
    let apiMethod = "PUT";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Ticker: item.ticker,
      Name: item.name,
      Description: item.description,
      Price: item.price,
    });

    return result;
  };

  const updateCryptoTransaction = async (item) => {
    let requestUrl = `/Crypto/UpdateCryptoTransaction/${cookies.userId}/${item.id}`;
    let apiMethod = "PUT";

    console.log(item);

    const result = await makeApiCall(apiMethod, requestUrl, {
      CryptoCurrencyId: item.cryptoCurrencyId,
      CryptoAmount: item.cryptoAmount,
      FiatCurrency: item.fiatCurrency,
      FiatAmount: item.fiatAmount,
      Description: item.description,
      TransactionDate: item.transactionDate,
    });

    return result;
  };

  // PATCH

  const updateCryptoCurrencyPrices = async () => {
    let requestUrl = `/Crypto/UpdateCryptoCurrencyPrices/${cookies.userId}`;
    let apiMethod = "PATCH";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  // DELETE

  const deleteCryptoCurrency = async (item) => {
    const requestUrl = `/Crypto/DeleteCryptoCurrency/${cookies.userId}/${item.id}`;
    let apiMethod = "DELETE";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  const deleteCryptoTransaction = async (item) => {
    const requestUrl = `/Crypto/DeleteCryptoTransaction/${cookies.userId}/${item.id}`;
    let apiMethod = "DELETE";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  return {
    getCryptoCurrencies,
    getCryptoTransactions,
    createCryptoCurrency,
    createCryptoTransaction,
    updateCryptoCurrency,
    updateCryptoTransaction,
    updateCryptoCurrencyPrices,
    deleteCryptoCurrency,
    deleteCryptoTransaction,
  };
};

export default useCryptoData;
