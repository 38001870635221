import { useState, useEffect } from "react";
import useAssetsData from "../services/DataService/AssetsData";
import useAssetsStore from "../store/AssetsStore";

const useAssetsLogic = () => {
  const {
    setLoading,
    dataChanged,
    setDataChanged,
    setAssets,
    setAssetTypes,
    resetAssetsStore,
  } = useAssetsStore();

  const {
    getAssets,
    getAssetTypes,
    createAsset,
    createAssetType,
    updateAsset,
    updateAssetType,
    deleteAsset,
    deleteAssetType,
  } = useAssetsData();

  const [refreshAssets, setRefreshAssets] = useState(false);

  useEffect(() => {
    if (dataChanged) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanged, refreshAssets]);

  const loadData = async () => {
    try {
      setLoading(true);

      let assetTypesData = await loadAssetTypes();
      setAssetTypes(assetTypesData);

      let assetsData = await loadAssets();
      setAssets(assetsData);
    } catch (error) {
      console.error("Failed to fetch assets data: ", error);
    } finally {
      setDataChanged(false);
      setLoading(false);
    }
  };

  // LOAD

  const loadAssetTypes = async () => {
    let assetTypesData = JSON.parse(localStorage.getItem("assetTypes"));

    if (!assetTypesData) {
      assetTypesData = await getAssetTypes();

      if (assetTypesData) {
        assetTypesData.sort((a, b) => a.name.localeCompare(b.name));

        localStorage.setItem("assetTypes", JSON.stringify(assetTypesData));
      }
    }

    return assetTypesData;
  };

  const loadAssets = async (assetTypesData) => {
    let assetsData = JSON.parse(localStorage.getItem("assets"));

    if (!assetsData) {
      assetsData = await getAssets(assetTypesData);
      if (assetsData) {
        localStorage.setItem("assets", JSON.stringify(assetsData));
      }
    }

    return assetsData;
  };

  // ADD

  const addAsset = async (item) => {
    const result = await createAsset(item);

    localStorage.removeItem("assets");
    setDataChanged(true);

    if (result?.status !== 200) {
      return result;
    }
  };

  const addAssetType = async (item) => {
    const result = await createAssetType(item);

    localStorage.removeItem("assetTypes");
    setDataChanged(true);

    if (result?.status !== 200) {
      return result;
    }
  };

  // EDIT

  const editAsset = async (item) => {
    const result = await updateAsset(item);

    localStorage.removeItem("assets");
    setDataChanged(true);

    if (result?.status !== 200) {
      return result;
    }
  };

  const editAssetType = async (item) => {
    const result = await updateAssetType(item);

    localStorage.removeItem("assetTypes");
    setDataChanged(true);

    if (result?.status !== 200) {
      return result;
    }
  };

  // REMOVE

  const removeAsset = async (item) => {
    const result = await deleteAsset(item);

    localStorage.removeItem("assets");
    setDataChanged(true);

    if (result?.status !== 200) {
      return result;
    }
  };

  const removeAssetType = async (item) => {
    const result = await deleteAssetType(item);

    localStorage.removeItem("assetTypes");
    setDataChanged(true);

    if (result?.status !== 200) {
      return result;
    }
  };

  // CLEAR

  const clearAssetsCache = () => {
    localStorage.removeItem("assets");
    localStorage.removeItem("assetTypes");

    resetAssetsStore();

    setDataChanged(true);
  };

  return {
    addAsset,
    addAssetType,
    editAsset,
    editAssetType,
    removeAsset,
    removeAssetType,
    clearAssetsCache,
    setRefreshAssets,
  };
};

export default useAssetsLogic;
