import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Account from "./Account/Account";
import Settings from "./Settings/Settings";
import useCurrentUserLogic from "../../../hooks/CurrentUserLogic";
import useCurrentUserStore from "../../../store/CurrentUserStore";
import useAppLogic from "../../../hooks/AppLogic";
import { FaBars } from "react-icons/fa";

const Menu = () => {
  const { clearAppCache } = useAppLogic();

  const { currentUser, currentUserInfo } = useCurrentUserStore();
  const { editUser, editUserInfo, changePassword, logoutCurrentUser } =
    useCurrentUserLogic();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const navigate = useNavigate();

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleOpenAccount = () => {
    setIsAccountOpen(true);
    setIsDropdownOpen(false);
  };
  const handleCloseAccount = () => setIsAccountOpen(false);

  const handleOpenSettings = () => {
    setIsSettingsOpen(true);
    setIsDropdownOpen(false);
  };
  const handleCloseSettings = () => setIsSettingsOpen(false);

  const handleLogOut = async () => {
    await logoutCurrentUser();

    navigate("/login");

    clearAppCache(true);
  };

  return (
    <div className="relative flex" ref={modalRef}>
      <button
        className="bg-transparent border-none text-white hover:opacity-80 text-xl cursor-pointer"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <FaBars size={20} />
      </button>

      {isDropdownOpen && (
        <div className="absolute top-full right-0 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-300 rounded-lg shadow-lg w-36 z-50 mt-1">
          <div className="font-semibold text-center">
            <span>{currentUser ? currentUser.username : "N/A"}</span>
            <hr className="mt-1 dark:opacity-40" />
          </div>

          <button
            className="block px-4 py-2 text-center w-full hover:bg-gray-100 dark:hover:bg-gray-700"
            onClick={handleOpenAccount}
          >
            Account
          </button>

          <button
            className="block px-4 py-2 text-center w-full hover:bg-gray-100 dark:hover:bg-gray-700"
            onClick={handleOpenSettings}
          >
            Settings
          </button>

          <button
            className="block px-4 py-2 text-center w-full hover:bg-gray-100 dark:hover:bg-gray-700 rounded-b-lg"
            onClick={handleLogOut}
          >
            Log out
          </button>
        </div>
      )}

      {isAccountOpen && (
        <Account
          currentUser={currentUser}
          currentUserInfo={currentUserInfo}
          editUser={editUser}
          editUserInfo={editUserInfo}
          changePassword={changePassword}
          onClose={handleCloseAccount}
        />
      )}

      {isSettingsOpen && <Settings onClose={handleCloseSettings} />}
    </div>
  );
};

export default Menu;
