import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Body from "../../components/Body/Body";
import { formatMoney } from "../../services/FormatService/FormatText";
import useAssetsLogic from "../../hooks/AssetsLogic";
import useAssetsStore from "../../store/AssetsStore";
import AssetsModal from "../../components/Assets/AssetsModal";
import AssetsList from "../../components/Assets/AssetsList/AssetsList";
import AssetsSettings from "../../components/Assets/AssetsSettings/AssetsSettings";
import { FaCog, FaSync } from "react-icons/fa";
import AddButton from "../../components/Body/AddButton";

const Assets = () => {
  const { addAsset, editAsset, removeAsset, clearAssetsCache } =
    useAssetsLogic();

  const { setLoading, assets } = useAssetsStore();

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingAsset, setEditingAsset] = useState(null);

  const openModal = (asset = null) => {
    setEditingAsset(asset);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditingAsset(null);
  };

  const handleOpenSettings = () => setIsSettingsOpen(true);
  const handleCloseSettings = () => setIsSettingsOpen(false);

  const handleSave = async (asset) => {
    setLoading(true);

    if (editingAsset) {
      await editAsset(asset);
    } else {
      await addAsset(asset);
    }
    closeModal();
  };

  const totalPurchasePrice = assets.reduce(
    (total, asset) => total + asset.purchasePrice,
    0
  );

  const totalCurrentWorth = assets.reduce(
    (total, asset) => total + asset.currentWorth,
    0
  );

  const percentageDifference =
    ((totalCurrentWorth - totalPurchasePrice) / totalPurchasePrice) * 100;

  return (
    <>
      <Header />

      <Body>
        <div className="absolute top-20 left-4">
          <button
            onClick={handleOpenSettings}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
          >
            <FaCog size={15} />
          </button>
        </div>

        <div className="absolute top-20 right-4">
          <button
            onClick={clearAssetsCache}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
          >
            <FaSync size={15} />
          </button>
        </div>

        <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
          {"Total Invested: "}
          <span className="font-bold">
            {formatMoney(totalPurchasePrice, 2)} €
          </span>
        </h2>

        <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
          {"Current Net Worth: "}
          <span className="font-bold">
            {formatMoney(totalCurrentWorth, 2)} €
          </span>
        </h2>

        {!isNaN(percentageDifference) && (
          <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
            {"Investment Growth: "}
            <span
              className={`font-bold ${
                percentageDifference < 0 ? "text-red-500" : "text-green-500"
              }`}
            >
              {percentageDifference > 0 && "+"}
              {formatMoney(totalCurrentWorth - totalPurchasePrice, 2)} € (
              {percentageDifference > 0 && "+"}
              {formatMoney(percentageDifference, 2)}%)
            </span>
          </h2>
        )}

        {/* Asset List */}
        <AssetsList openModal={openModal} />
      </Body>

      {/* Add Asset Button */}
      <AddButton openModal={openModal} />

      {/* Asset Modal */}
      {isModalOpen && (
        <AssetsModal
          initialData={editingAsset}
          onSave={handleSave}
          onDelete={removeAsset}
          onClose={closeModal}
        />
      )}

      {/* Settings Modal */}
      {isSettingsOpen && <AssetsSettings onClose={handleCloseSettings} />}
    </>
  );
};

export default Assets;
