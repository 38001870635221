import React from "react";
import { format } from "date-fns";
import { formatMoney } from "../../../services/FormatService/FormatText";

const CryptoTransactionsListItem = ({ transaction, openModal }) => {
  return (
    <div
      className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-1 text-center my-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg cursor-pointer"
      onClick={() => openModal(transaction)}
    >
      <p className="text-sm text-gray-600 dark:text-gray-300">
        {format(new Date(transaction.transactionDate), "dd.MM.yyyy.")}
      </p>

      <p className="text-sm text-gray-600 dark:text-gray-300 hidden lg:block">
        {transaction.description}
      </p>

      <p className="text-sm text-gray-600 dark:text-gray-300">
        {transaction.cryptoAmount}
      </p>

      <p className="text-sm text-gray-600 dark:text-gray-300 hidden md:block">
        {formatMoney(transaction.fiatAmount / transaction.cryptoAmount, 2)}
        {transaction.fiatCurrency}
      </p>

      <p
        className={`text-sm ${
          transaction.fiatAmount < 0 ? "text-red-500" : "text-green-500"
        }`}
      >
        {formatMoney(transaction.fiatAmount, 2)} {transaction.fiatCurrency}
      </p>
    </div>
  );
};

export default CryptoTransactionsListItem;
