import useAssetsStore from "../store/AssetsStore";
import useCryptoStore from "../store/CryptoStore";
import useCurrentUserStore from "../store/CurrentUserStore";
import useTransactionsStore from "../store/TransactionsStore";

const useAppLogic = () => {
  const { resetTransactionsStore } = useTransactionsStore();
  const { resetAssetsStore } = useAssetsStore();
  const { resetCurrentUserStore } = useCurrentUserStore();
  const { resetCryptoStore } = useCryptoStore();

  const clearAppCache = (isLogOut) => {
    localStorage.clear();

    resetCurrentUserStore();
    resetTransactionsStore();
    resetAssetsStore();
    resetCryptoStore();

    const message = "Local Cache cleared!";
    console.log(message);

    if (!isLogOut) {
      alert(message);
      window.location.reload();
    }
  };

  return {
    clearAppCache,
  };
};

export default useAppLogic;
