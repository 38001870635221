import { useCookies } from "react-cookie";
import { makeApiCall } from "../ApiService/ApiCall";

const useAssetsData = () => {
  const [cookies] = useCookies();

  // GET

  const getAssets = async () => {
    const requestUrl = `/Assets/GetAssets/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  const getAssetTypes = async () => {
    const requestUrl = `/Assets/GetAssetTypes/${cookies.userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    const apiAssetTypes = result.data.map((element) => ({
      id: element.id,
      name: element.name,
      description: element.description,
    }));

    return apiAssetTypes;
  };

  // POST

  const createAsset = async (item) => {
    let requestUrl = `/Assets/CreateAsset/${cookies.userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      AssetTypeId: item.assetTypeId,
      Name: item.name,
      Description: item.description,
      PurchasePrice: item.purchasePrice,
      Amount: item.amount,
      CurrentWorth: item.currentWorth,
      PurchaseDate: item.purchaseDate,
    });

    return result;
  };

  const createAssetType = async (item) => {
    let requestUrl = `/Assets/CreateAssetType/${cookies.userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Name: item.name,
      Description: item.description,
    });

    return result;
  };

  // PUT

  const updateAsset = async (item) => {
    let requestUrl = `/Assets/UpdateAsset/${item.id}`;
    let apiMethod = "PUT";

    const result = await makeApiCall(apiMethod, requestUrl, {
      AssetTypeId: item.assetTypeId,
      Name: item.name,
      Description: item.description,
      PurchasePrice: item.purchasePrice,
      Amount: item.amount,
      CurrentWorth: item.currentWorth,
      PurchaseDate: item.purchaseDate,
    });

    return result;
  };

  const updateAssetType = async (item) => {
    let requestUrl = `/Assets/UpdateAssetType/${cookies.userId}/${item.id}`;
    let apiMethod = "PUT";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Name: item.name,
      Description: item.description,
    });

    return result;
  };

  // DELETE

  const deleteAsset = async (item) => {
    const requestUrl = `/Assets/DeleteAsset/${item.id}`;
    let apiMethod = "DELETE";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  const deleteAssetType = async (item) => {
    const requestUrl = `/Assets/DeleteAssetType/${cookies.userId}/${item.id}`;
    let apiMethod = "DELETE";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  return {
    getAssets,
    getAssetTypes,
    createAsset,
    createAssetType,
    updateAsset,
    updateAssetType,
    deleteAsset,
    deleteAssetType,
  };
};

export default useAssetsData;
